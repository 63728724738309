.vertical-timeline::before {
    /* this is the vertical line */

    background: #362706 !important;
    height: 70% !important;
    top: 31px !important;
}

.vertical-timeline {
    width: 70% !important;
}


 .vertical-timeline-element-icon {
    width: 30px !important;
    height: 30px !important;
    margin-left: -14px;
    background-color: #362706 !important;
    /* border: 5px solid #fff; */
}

.vertical-timeline-element-icon {
    .check-icon {
        margin-left: 2px;
    }
}

.vertical-timeline-element--work {
    .disImage {
        margin-left: 2px;
        margin-top: 10px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        //   width: auto;

    }
}

.vertical-timeline--animate {
    .vertical-timeline-element-content.bounce-in {
        border-radius: 20px;
        color: #333 !important;
    }
}
@media only screen and (min-width: 1170px){
.vertical-timeline--two-columns .vertical-timeline-element-icon {
    margin-left: -14px !important;
}
}

@media only screen and (min-width:768px) and (max-width:1170px) {
    .vertical-timeline {
        width: 50% !important;
    }
}

@media only screen and (max-width:1170px) {
    .vertical-timeline-element-icon {
        margin-left: 5px !important;
    }
}

@media only screen and (max-width:480px) {
    .vertical-timeline {
        width: 100% !important;
    }

  
}
@media only screen and (max-width:640px){
      .vertical-timeline-element--work {
        .disImage {
            margin-left: 2px;
            margin-top: 10px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: -webkit-fill-available;
            max-width: 100%;

        }
    }
    .vertical-timeline::before {
    /* this is the vertical line */

    height: 68% !important;
}
}