.TooltipP {
  display: inline;
  position: relative;
}

.TooltipP:hover:before {
  background: #1a1a1a;
  border-radius: 5px;
  bottom: 115px;
  color: #fff;
  content: "Design";
  left: 0;
  padding: 5px 15px;
  position: absolute;
  z-index: 98;
  width: 130%;
  text-align: center;
}
.TooltipP:hover:after {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 125px;
  border: 15px solid transparent;
  border-bottom: 0;
  border-top: 15px solid #1a1a1a;
  transform: translate(-50%, calc(100% + 5px));
}
.TooltipW {
  display: inline;
  position: relative;
}

.TooltipW:hover:before {
  background: #1a1a1a;
  border-radius: 5px;
  bottom: 115px;
  color: #fff;
  content: "Weaving / Dying";
  white-space: nowrap;
  left: -10%;
  padding: 5px 15px;
  position: absolute;
  z-index: 98;
  width: 130%;
  text-align: center;
}
.TooltipW:hover:after {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 125px;
  border: 15px solid transparent;
  border-bottom: 0;
  border-top: 15px solid #1a1a1a;
  transform: translate(-50%, calc(100% + 5px));
}
.TooltipF {
  display: inline;
  position: relative;
}

.TooltipF:hover:before {
  background: #1a1a1a;
  border-radius: 5px;
  bottom: 115px;
  color: #fff;
  content: "Finishing";
  white-space: nowrap;
  left: -10%;
  padding: 5px 15px;
  position: absolute;
  z-index: 98;
  width: 130%;
  text-align: center;
}
.TooltipF:hover:after {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 125px;
  border: 15px solid transparent;
  border-bottom: 0;
  border-top: 15px solid #1a1a1a;
  transform: translate(-50%, calc(100% + 5px));
}
.CreateNewBtn {
  width: 100%;
  background-color: #262d22;
}
.imagediv {
  position: relative;
}
.closeIcon {
  position: absolute;
  right: -70px;
  top: 18px;
}

.dbtn {
  display: flex;
  justify-content: space-between;
  margin: 10px 19px;
  .Previous {
    width: 150px;
    color: #000 !important;
    background-color: #fff !important;
    border-radius: 10px;
  }
  .Next {
    width: 150px;
    background-color: #000 !important;
    color: #fff !important;
    border-radius: 10px;
  }
}
.dbtn1 {
  display: flex;
  justify-content: space-between;
  margin: 10px 19px;

  .Next,
  .Previous {
    width: 150px;
    background-color: #000 !important;
    color: #fff !important;
    border-radius: 10px;
  }
}
.dbtn3 {
  display: flex;
  justify-content: space-between;
  margin: 10px 19px;
  .Next {
    width: 150px;
    color: #000 !important;
    background-color: #fff !important;
    border-radius: 10px;
  }
  .Previous {
    width: 150px;
    background-color: #000 !important;
    color: #fff !important;
    border-radius: 10px;
  }
}
.save {
  margin-right: 40px;
  margin-bottom: 50px;
  width: 150px;
  float: right;
  background-color: #000;
  border-radius: 10px;
}
.designingIcons {
  display: flex;
  justify-content: space-around;
  height: 300px;
  align-items: center;
}
.icons {
  // margin: 30px;
  margin-right: 80px;
  margin-left: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 300px;
  .Line {
    border-bottom: 1px solid black;
    width: 100%;
  }
  .LineColr {
    border-bottom: 5px solid #c59c6c;
    width: 100%;
  }
  svg {
    width: 100px;
    height: 100px;
    cursor: pointer;
  }
}
.ViewImageQrCode {
  display: flex;
  justify-content: space-evenly;
}
.AddTag {
  padding: 10px 30px;
  background-color: #c59c6c;
}
.textNone {
  text-transform: none !important;
}
.VideoDiv {
  position: relative;
  svg {
    position: absolute;
    z-index: 999;
    right: 30px;
    color: white;
    top: 10px;
    cursor: pointer;
  }
}

.viewProductQR {
  overflow-y: hidden;
  height: 100%;
  overflow-x: hidden;
  margin: 20px;
  margin-bottom: 40px !important;
  .ProdDivLine {
    height: 498px;
    border-right: 1px solid #d9d9d9;
  }
  .ProdRow {
    width: 100%;
    margin-left: 0px;
    padding-right: 20px;
    border: 1px solid #d9d9d9;
    height: 498px;
  }

  .RowTextPro {
    border-bottom: 1px solid #d9d9d9;
    height: 83px;
    width: 110%;
    margin-left: 0px;
    .ColTextPro {
      height: 50px;
      margin-top: 10px;
      border-right: 1px solid #d9d9d9;
    }
    .ColTextPro1 {
      height: 50px;
      margin-top: 10px;
      text-transform: capitalize;
    }
    .Text {
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      align-items: center;
      display: flex;
      height: 50px;
      text-align: center;
      margin-left: 100px;
    }
  }
  .imageDivProd {
    position: relative;
    padding: 20px;
    img {
      height: 400px;
    }
    .svg1 {
      position: absolute;
      right: 35px;
      top: 35px;
    }
    .ProdText {
      font-family: Roboto;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      padding-top: 10px;
    }
    .verified {
      position: absolute;
      display: flex;
      left: 42px;
      bottom: 87px;
      background: #c59c6c;
      border-radius: 4px;
      width: 250px;
      height: 30px;
      margin-bottom: 10px;
      svg {
        margin-left: 5px;
        margin-top: 2px;
      }
      span {
        color: #262d22;
        font-size: 15px;
        margin-top: 3px;
        margin-left: 5px;
      }
    }
  }
  .UserHeader {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 18px;
    padding-left: 20px;
    padding-top: 40px;
    padding-bottom: 30px;
    border: 1px solid #d9d9d9;
  }
  .DesignDiv {
    border: 1px solid #d9d9d9;
    height: 330px;
    margin-top: 20px;
  }
  .DesignerHeader {
    height: 70px;
    display: flex;
    align-items: center;
    font-size: 20px;
    border-bottom: 1px solid #d9d9d9;
    padding: 20px;
    span {
      margin-left: 5px;
      text-transform: capitalize;
    }
  }
  .ArryImag {
    background: #f5f5f5;
    border: 0.5px solid #c6c6c6;
    border-radius: 4px;
    height: 220px;
    width: 98%;
    margin: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    position: relative;
    .ImageDIVDIV {
      margin: 20px;
      position: relative;
      .Preview {
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
      }
    }
  }
}
.NoUploadImg {
  height: 80px;
  align-items: center;
  display: flex;
  font-size: 12px;
  border: 0.5px dashed #c59c6c;
  padding-left: 10px;
}
// QRCode
.BannerDiv {
  position: relative;
  .TextDec {
    text-transform: capitalize;
    font-weight: 1000;
  }
  .BannerImage {
    height: 650px;
  }
  .lines {
    position: absolute;
    left: 51px;
    display: flex;
    .line1 {
      border-left: 5px solid #c59c6c;
      position: absolute;
      height: 100vh;
    }
    .line2 {
      border-left: 2px solid #c59c6c;
      position: absolute;
      height: 100vh;
      margin-left: 8px;
    }
  }
  .lines1 {
    position: absolute;
    right: 71px;
    display: flex;
    .line1 {
      border-left: 5px solid #c59c6c;
      position: absolute;
      height: 100vh;
    }
    .line2 {
      border-left: 2px solid #c59c6c;
      position: absolute;
      height: 100vh;
      margin-left: 8px;
    }
  }
  .BannerContent {
    position: absolute;
    width: 100%;
    top: 50px;
    .cerDiv {
      border: 1px solid #c59c6c;
    }
    .cerDiv1,
    .cerDiv {
      width: 663px;
      border-radius: 10px;
      margin: auto;
      padding: 10px;
    }
    .certificationAuthenticity {
      display: flex;
      justify-content: center;
      font-family: "Roboto";
      font-size: 38px;
      // font-style: italic;
      color: #c59c6c;
      font-weight: 700;
    }
    .certificationAuthenticityDes {
      // display: flex;
      // justify-content: center;
      width: 100%;
      margin: auto;
      text-align: center;
      font-family: "Roboto";
      font-size: 20px;
      // font-style: italic;
      color: #fff;
      padding: 5px;
    }

    .Banner1 {
      display: flex;
      justify-content: center;
      align-items: center;
      .leftImage,
      .rightImage {
        padding: 10px;
        margin-right: 20px;
        // border-radius: 50%;
        object-fit: fill;
      }
      .leftImage {
        width: 150px;
        height: 150px;
      }
      .rightImage {
        width: 180px;
        height: 180px;
      }
      .line {
        // border-right: 0.5px solid #b0adad;
        // margin: 40px 11px 40px 0px;

        min-height: 100px;
        border-right: 1px solid #b0adad;
        color: #fff;
      }
      .orgName {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        background-color: #88771a;
        text-align: center;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: break-spaces;
        font-size: 19px;
        text-transform: capitalize;
        font-family: "Roboto";
        font-weight: 700;
        margin-right: 20px;
      }
    }
    .Banner2 {
      text-align: center;
      width: 100%;
      top: 50px;
      .Floral {
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        color: #c59c6c;
        margin-top: 30px;
      }
      .AllProducts {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 18px;
        /* identical to box height */

        text-align: center;

        color: #ffffff;
        margin-top: 20px;
      }
      .Verified {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        color: #ffffff;
        border: 1px solid #c59c6c;
        border-radius: 4px;
        height: 100px;
        padding: 30px;
        margin-top: 8px;
        .d1 {
          padding-left: 30px;
        }
      }
    }
  }
}
.CardViewProductHeader {
  padding: 30px;
  border: none;
  .tableProductHeader {
    background: #404b3b;
    border-radius: 4px;
    height: 80px;
    display: flex;
    color: #ffff;
    align-items: center;
    font-size: 30px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    justify-content: center;
  }

  .ImageDis {
    height: 500px;
    object-fit: contain;
    z-index: 1;
    width: 100%;
  }
  .ImageDis-bg-bg {
    height: 100%;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .ImageDis-bg {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(10px);
  }
  .NoPreview {
    height: 500px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: cem;
    justify-content: center;
    border: 1px solid #ead6c0;
  }
  .RowTextPro1 {
    background: #f8f8f8;
  }
  .RowTextPro,
  .RowTextPro1 {
    height: 83px;
    width: 100%;
    margin-left: 0px;
    align-items: center;
    .ColTextPro {
      height: 50px;
      margin-top: 10px;
    }
    .ColTextPro1 {
      height: 50px;
      margin-top: 10px;
      text-transform: capitalize;
    }
    .Text {
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      align-items: center;
      display: flex;
      height: 50px;
      text-align: center;
      margin-left: 100px;
    }
  }
  .ProductJourneyPart {
    background: #fff2e3;
    border: 0.5px solid #c59c6c;
    border-radius: 4px;
    height: auto;
    padding-bottom: 40px;
    margin-top: 50px;
    .rightCard {
      .rightToolTipCard {
        position: relative;
        .TriangleIcon {
          position: absolute;
          left: -16px;
          top: 15px;
        }
      }
    }
    .leftCard {
      .rightToolTipCard {
        position: relative;
        .TriangleIcon {
          position: absolute;
          right: 3px;
          top: 15px;
        }
        .leftArrow {
          position: absolute;
          right: -19px;
        }
      }
    }
    .rightToolTipSubCard {
      background-color: #1a1a1a;
      border-radius: 4px;
      height: 59px;
      // display: flex;
      align-items: center;
      font-family: Roboto;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      /* identical to box height */

      color: #ffffff;
      div {
        padding-left: 30px;
      }
      .line {
        height: 70%;
        border-right: 0.5px solid #b0adad;
        align-items: center;
        display: flex;
        padding-left: 30px;
      }
    }
    .ProductJourneyText {
      font-family: Roboto;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      /* identical to box height */

      text-align: center;

      color: #404b3b;
      margin-top: 40px;
      margin-bottom: 30px;
    }
    .Row {
      position: relative;
      .verticleLine {
        border-right: 5px solid #c59c6c;
        height: 358px;
      }
      .verticleLine1 {
        border-right: 5px solid #c59c6c;
        height: 120px;
      }
      .Icon1 {
        position: relative;
        left: 41px;
        top: 28px;
      }
      .rightCard,
      .leftCard {
        padding: 40px;
        .subDiv {
          font-family: Roboto;
          font-style: normal;
          margin-top: 17px;
        }
        .SmallHeading {
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          /* identical to box height */
          margin-left: 10px;
          color: #484848;
        }
        .SmallText {
          margin-left: 10px;
          text-transform: capitalize;
        }
        .subDiv1 {
          background: #ead6c0;
          border: 0.5px dashed #c59c6c;
          border-radius: 4px;
          margin-top: 20px;
          height: 100px;
          display: flex;
          justify-content: flex-start;
          .imagePos {
            cursor: pointer !important;
            position: relative;
            margin-top: 10px;
            margin-left: 20px;
            .Number {
              position: absolute;
              width: 25px;
              height: 25px;
              border-radius: 50%;
              background-color: #fff;
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              right: 6px;
              top: 6px;
            }
            img,
            iframe,
            video {
              border: 0.5px dashed #c59c6c;
              // z-index: 0;
              // width: 100%;
            }
          }
          .subDiv12 {
            font-family: Roboto;
            font-style: normal;
            display: inline-flex;
            justify-content: space-between;
            width: 100%;
            padding: 26px 30px 20px 30px;
            .ViewIcon {
              margin-top: 5px;
              cursor: pointer;
            }
          }
          .SmallHeading {
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            /* identical to box height */
            margin-left: 10px;
            color: #484848;
          }
          .SmallText {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
//QRModal
.ModalDiaLOgue {
  .modal-dialog {
    min-width: 100vw !important;
  }
}
.QRModalPopUp {
  min-width: 100vw !important;
  overflow-y: auto;
  overflow-x: hidden;
  .slick-next:before {
    content: url(../../assets/images/icons8-chevron-right-50.png) !important;
    z-index: 999;
  }
  .slick-prev:before {
    content: url(../../assets/images/icons8-chevron-left-50.png) !important;
    z-index: 999;
  }
  .slick-next,
  .slick-next:before,
  .slick-prev::before,
  .slick-prev {
    width: 50px !important;
    height: 50px !important;
    z-index: 9999;
  }
  .Heading {
    svg {
      cursor: pointer;
    }
  }
  .modal-content {
    background: #1a1a1a;
    opacity: 0.88;
    min-width: 100vw;
    // min-height: 97vh;
    // left: -67%;
    top: -20px;
  }
  .modal-header {
    border-bottom: none;
    // position: relative;
    // right: 10px;
    .modal-title {
      display: contents;
      .Heading {
        position: relative;
        right: 10px;
      }
    }
  }
  .modal-footer {
    border-top: none;
    justify-content: flex-start;
  }
  .imageDiv {
    display: flex;
    justify-content: center;
    min-width: 100%;
    height: 100%;
    img,
    video {
      width: 700px;
      // height: 600px;
      // object-fit: contain;
    }
    iframe {
      width: 80%;
      height: 70vh;
      min-height: 100%;
    }
  }
  .modal-body {
    padding: 10px 286px;
    border-bottom: none;
  }
  .ZoomDiv {
    height: 36px;
    background: #1a1a1a;
    border: 0.5px solid #484848;
    border-radius: 15.5px;
    width: 100px;
    margin-left: 10%;
    display: flex;
    justify-content: space-between;
    padding: 5px 12px;
    span {
      height: 20px;
      border-left: 0.5px solid;
      margin-top: 5px;
    }
    svg {
      cursor: pointer;
    }
  }
}

// Modal
@media (max-width: 640px) {
  .QRModalPopUp {
    .slick-slider {
      margin: 10px 35px;
    }
    .modal-header {
      border-bottom: none;
      .modal-title {
        display: unset;
        .Heading {
          position: fixed;
          right: 10px;
        }
      }
      // justify-content: flex-end;
    }
    .imageDiv {
      img,
      video {
        width: 70%;
        height: 300px;
        // margin-top: 130px;
      }
    }
    .modal-body {
      padding: 26px;
      border-bottom: none;
    }
    .ZoomDiv {
      display: none;
    }
  }
}
@media (min-width: 640px) and (max-width: 868px) {
  .QRModalPopUp {
    .modal-header {
      border-bottom: none;
      .modal-title {
        display: unset;
        .Heading {
          position: fixed;
          right: 10px;
        }
      }
    }
    .imageDiv {
      img,
      video {
        width: 70%;
        // height: 300px;
        // margin-top: 130px;
      }
    }
    .modal-body {
      padding: 26px;
      border-bottom: none;
    }
    .ZoomDiv {
      display: none;
    }
  }
}
@media (min-width: 869px) and (max-width: 1024px) {
  .QRModalPopUp {
    .modal-header {
      border-bottom: none;
    }
    .imageDiv {
      img,
      video {
        width: 70%;
        // height: 300px;
        // margin-top: 130px;
      }
    }
    .modal-body {
      padding: 26px;
      border-bottom: none;
    }
  }
}
@media (min-width: 1025px) and (max-width: 1336px) {
  .QRModalPopUp {
    .modal-header {
      border-bottom: none;
    }
    .imageDiv {
      img,
      video {
        width: 70%;
        // height: 300px;
        // margin-top: 130px;
      }
    }
    .modal-body {
      padding: 40px;
      border-bottom: none;
    }
  }
}

@media (min-width: 1599px) {
  .viewProductQR .ArryImag .ImageDIVDIV .Preview {
    right: 35px;
  }
  .preview {
    width: 100%;
  }
}
@media (max-width: 899px) {
  .ProdDivLine {
    border: unset !important;
    height: unset !important;
  }
}
@media (max-width: 1560px) {
  .imageDiv1 {
    img {
      margin-right: 10px;
      width: 90% !important;
    }
    iframe,
    video {
      width: 90% !important;
    }
  }
}
@media (min-width: 1361px) and (max-width: 1560px) {
  .ProdDivLine {
    height: 330px !important;
  }
  .QRModal {
    .modal-content {
      padding: 10px !important;
    }
    .Heading {
      h3 {
        font-size: 20px;
      }
      svg {
        width: 20px;
        margin-top: 10px;
      }
    }
    img {
      width: 100%;
      height: 500px;
    }
    iframe,
    embed,
    .video {
      height: 600px;
    }
  }
  .createProduct {
    .imageDiv1 {
      height: auto !important;
      .PreviewDivPart {
        width: unset;
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr !important;
        margin-top: 30px;
      }
    }
  }
  .CardViewProductHeader {
    .ImageDis,
    .NoPreview {
      height: 432px;
    }
    .RowTextPro,
    .RowTextPro1 {
      height: 71px;
      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 18px;
      }
    }
  }
  .viewProductQR {
    .DesignDiv {
      height: 100%;
      .DesignerHeader {
        font-size: 20px;
        text-transform: capitalize;
        // span{
        //   text-transform: capitalize;
        // }
      }
      .ArryImag {
        width: unset;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        .ImageDIVDIV {
          img,
          iframe,
          embed,
          video,
          .preview {
            width: 100% !important;
            height: 250px;
          }
        }
      }
    }
    .RowTextPro {
      height: 68.5px;
      width: 103.8%;
      margin-left: -1px;
      border-bottom: unset !important;
      border: 1px solid #d9d9d9 !important;
      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 18px;
      }
    }
    .UserHeader {
      font-size: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .ProdRow {
      height: 413px;
    }
    .imageDivProd {
      svg {
        right: 30px !important;
        top: 30px !important;
        width: 15%;
      }
      img {
        height: 330px;
        border: 1px solid;
      }
      .ProdText {
        font-size: 16px;
      }
      .verified {
        left: 30px;
        bottom: 89px;
        width: 180px;
        span {
          font-size: 10px;
          margin-top: 8px;
        }
      }
    }
  }
}
@media (min-width: 1125px) and (max-width: 1360px) {
  .ProdDivLine {
    height: 330px !important;
  }
  .QRModal {
    .modal-content {
      padding: 10px !important;
    }
    .Heading {
      h3 {
        font-size: 20px;
      }
      svg {
        width: 20px;
        margin-top: 10px;
      }
    }
    img {
      width: 100%;
      height: 500px;
    }
    iframe,
    embed,
    video {
      height: 600px;
    }
  }
  .createProduct {
    .imageDiv1 {
      height: auto !important;
      .PreviewDivPart {
        width: unset;
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
      }
    }
  }
  .CardViewProductHeader {
    .ImageDis,
    .NoPreview {
      height: 432px;
    }
    .RowTextPro,
    .RowTextPro1 {
      height: 71px;
      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 16px;
      }
    }
  }
  .viewProductQR {
    .DesignDiv {
      height: 100%;
      .DesignerHeader {
        font-size: 20px;
      }
      .ArryImag {
        width: unset;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        .ImageDIVDIV {
          img,
          iframe,
          embed,
          video,
          .preview {
            width: 100% !important;
            height: 250px;
          }
        }
      }
    }
    .RowTextPro {
      height: 59.5px;
      width: 103.8%;
      margin-left: -1px;
      border-bottom: unset !important;
      border: 1px solid #d9d9d9 !important;
      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 16px;
      }
    }
    .UserHeader {
      font-size: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .ProdRow {
      height: 358px;
    }
    .imageDivProd {
      svg {
        right: 30px !important;
        top: 16px !important;
        width: 15%;
      }
      img {
        height: 230px;
        border: 1px solid;
      }
      .ProdText {
        font-size: 16px;
      }
      .verified {
        left: 30px;
        bottom: 99px;
        width: 180px;
        span {
          font-size: 10px;
          margin-top: 8px;
        }
      }
    }
  }
}
@media (min-width: 900px) and (max-width: 1360px) {
  .createProduct {
    .imageDiv1 {
      height: auto !important;
      .PreviewDivPart {
        width: unset;
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
        margin-top: 30px;
      }
    }
  }
}
@media (min-width: 900px) and (max-width: 1124px) {
  .ProdDivLine {
    height: 330px !important;
  }
  .QRModal {
    .modal-content {
      padding: 10px !important;
    }
    .Heading {
      h3 {
        font-size: 16px;
      }
      svg {
        width: 10px;
        margin-top: 10px;
      }
    }
    img {
      width: 100%;
      height: 300px;
    }
    iframe,
    embed,
    video {
      height: 600px;
    }
  }
  .CardViewProductHeader {
    .ImageDis,
    .NoPreview {
      height: 430px;
    }
    .RowTextPro,
    .RowTextPro1 {
      height: 70px;
      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 14px;
      }
    }
  }
  .viewProductQR {
    .DesignDiv {
      height: 100%;
      .DesignerHeader {
        font-size: 16px;
      }
      .ArryImag {
        width: unset;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .ImageDIVDIV {
          img,
          iframe,
          embed,
          video,
          .preview {
            width: 100% !important;
            height: 250px;
          }
        }
      }
    }
    .RowTextPro {
      height: 55px;
      width: 103.8%;
      margin-left: -1px;
      border-bottom: unset !important;
      border: 1px solid #d9d9d9 !important;
      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 14px;
      }
    }
    .UserHeader {
      font-size: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .ProdRow {
      height: 331px;
    }
    .imageDivProd {
      svg {
        right: 30px !important;
        top: 16px !important;
        width: 15%;
      }
      img {
        height: 230px;
        border: 1px solid;
      }
      .ProdText {
        font-size: 12px;
      }
      .verified {
        left: 30px;
        bottom: 85px;
        width: 180px;
        span {
          font-size: 10px;
          margin-top: 8px;
        }
      }
    }
  }
}
@media (min-width: 769px) and (max-width: 899px) {
  .QRModal {
    .modal-content {
      padding: 10px !important;
    }
    .Heading {
      h3 {
        font-size: 16px;
      }
      svg {
        width: 10px;
        margin-top: 10px;
      }
    }
    img {
      width: 100%;
      height: 300px;
    }
    iframe,
    embed,
    video {
      height: 600px;
    }
  }
  .createProduct {
    .imageDiv1 {
      height: auto !important ;
      .PreviewDivPart {
        width: unset;
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr !important;
        margin-top: 30px;
      }
    }
  }
  .CardViewProductHeader {
    .ImageDis,
    .NoPreview {
      height: 367px;
    }
    .RowTextPro,
    .RowTextPro1 {
      height: 60px;

      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 18px;
      }
    }
  }
  .viewProductQR {
    .DesignDiv {
      height: 100%;
      .DesignerHeader {
        font-size: 16px;
      }
      .ArryImag {
        width: unset;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        .ImageDIVDIV {
          img,
          iframe,
          embed,
          video,
          .preview {
            width: 100% !important;
            height: 250px;
          }
        }
      }
    }
    .RowTextPro {
      height: 51px;
      width: 102.8%;
      margin-left: -1px;
      border-bottom: unset !important;
      border: 1px solid #d9d9d9 !important;
      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 18px;
      }
    }
    .UserHeader {
      font-size: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .ProdRow {
      height: 522px;
    }
    .imageDivProd {
      padding: 20px 0px 20px 20px !important;
      svg {
        right: 10px !important;
        top: 30px !important;
        width: 15%;
      }
      img {
        height: 450px;
        border: 1px solid;
      }
      .ProdText {
        font-size: 14px;
      }
      .verified {
        left: 30px;
        bottom: 72px;
        width: 180px;
        span {
          font-size: 10px;
          margin-top: 8px;
        }
      }
    }
    .UserDetailsDiv {
      height: 893px;
    }
  }
}
@media (min-width: 641px) and (max-width: 768px) {
  .QRModal {
    .modal-content {
      padding: 10px !important;
    }
    .Heading {
      h3 {
        font-size: 16px;
      }
      svg {
        width: 10px;
        margin-top: 10px;
      }
    }
    img {
      width: 100%;
      height: 300px;
    }
    iframe,
    embed,
    video {
      height: 400px;
    }
  }
  .createProduct {
    .imageDiv1 {
      height: auto !important;
      .PreviewDivPart {
        width: unset;
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
      }
    }
  }

  .CardViewProductHeader {
    .ImageDis,
    .NoPreview {
      height: 367px;
    }
    .RowTextPro,
    .RowTextPro1 {
      height: 71px;

      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 16px;
      }
    }
  }
  .viewProductQR {
    .DesignDiv {
      height: 100%;
      .DesignerHeader {
        font-size: 16px;
      }
      .ArryImag {
        width: unset;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        .ImageDIVDIV {
          img,
          iframe,
          embed,
          video,
          .preview {
            width: 100% !important;
            height: 250px;
          }
        }
      }
    }
    .RowTextPro {
      height: 51px;
      width: 103.6%;
      margin-left: -1px;
      border-bottom: unset !important;
      border: 1px solid #d9d9d9 !important;
      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 16px;
      }
    }
    .UserHeader {
      font-size: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .ProdRow {
      height: 541px;
    }
    .imageDivProd {
      padding: 20px 0px 20px 20px !important;
      svg {
        right: 10px !important;
        top: 30px !important;
        width: 15%;
      }
      img {
        height: 450px;
        border: 1px solid;
      }
      .ProdText {
        font-size: 14px;
      }
      .verified {
        left: 30px;
        bottom: 72px;
        width: 180px;
        span {
          font-size: 10px;
          margin-top: 8px;
        }
      }
    }
    .UserDetailsDiv {
      height: 912px;
    }
  }
}
@media (min-width: 452px) and (max-width: 640px) {
  .QRModal {
    .modal-content {
      padding: 10px !important;
    }
    .Heading {
      h3 {
        font-size: 16px;
      }
      svg {
        width: 10px;
        margin-top: 10px;
      }
    }
    img {
      width: 100%;
      height: 300px;
    }
    iframe,
    embed,
    video {
      height: 400px;
    }
  }
  .CardViewProductHeader {
    .ImageDis,
    .NoPreview {
      height: 367px;
    }
    .RowTextPro,
    .RowTextPro1 {
      height: 61px;
      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 14px;
      }
    }
  }
  .viewProductQR {
    .DesignDiv {
      height: 100%;
      .DesignerHeader {
        font-size: 16px;
      }
      .ArryImag {
        width: unset;
        height: 100%;
        display: block;
        .ImageDIVDIV {
          img,
          iframe,
          embed,
          video,
          .preview {
            width: 100% !important;
            height: 250px;
          }
        }
      }
    }
    .RowTextPro {
      height: 51px;
      width: 104%;
      margin-left: -1px;
      border-bottom: unset !important;
      border: 1px solid #d9d9d9 !important;
      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 14px;
      }
    }
    .UserHeader {
      font-size: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .ProdRow {
      height: 390px;
    }
    .imageDivProd {
      padding: 20px 0px 20px 20px !important;
      svg {
        right: 10px !important;
        top: 30px !important;
        width: 15%;
      }
      img {
        height: 300px;
        border: 1px solid;
      }
      .ProdText {
        font-size: 14px;
      }
      .verified {
        left: 30px;
        bottom: 72px;
        width: 180px;
        span {
          font-size: 10px;
          margin-top: 8px;
        }
      }
    }
    .UserDetailsDiv {
      height: 764px;
    }
  }
}
@media (min-width: 421px) and (max-width: 451px) {
  .CardViewProductHeader {
    .RowTextPro,
    .RowTextPro1 {
      height: 61px;
      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 14px;
      }
    }
  }
  .QRModal {
    .modal-content {
      padding: 10px !important;
    }
    .Heading {
      h3 {
        font-size: 16px;
      }
      svg {
        width: 10px;
        margin-top: 10px;
      }
    }
    img {
      width: 100%;
      height: 200px;
    }
    iframe,
    embed,
    video {
      height: 400px;
    }
  }
  .viewProductQR {
    .DesignDiv {
      height: 100%;
      .DesignerHeader {
        font-size: 16px;
      }
      .ArryImag {
        width: unset;
        height: 100%;
        display: block;
        .ImageDIVDIV {
          img,
          iframe,
          embed,
          video,
          .preview {
            width: 100% !important;
          }
        }
      }
    }
    .RowTextPro {
      height: 51px;
      width: 106%;
      margin-left: -1px;
      border-bottom: unset !important;
      border: 1px solid #d9d9d9 !important;
      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 12px;
      }
    }
    .UserHeader {
      font-size: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .ProdRow {
      height: 340px;
    }
    .imageDivProd {
      padding: 20px 0px 20px 20px !important;
      svg {
        right: 10px !important;
        top: 22px !important;
        width: 15%;
      }
      img {
        height: 250px;
        border: 1px solid;
      }
      .ProdText {
        font-size: 14px;
      }
      .verified {
        left: 30px;
        bottom: 72px;
        width: 180px;
        span {
          font-size: 10px;
          margin-top: 8px;
        }
      }
    }
    .UserDetailsDiv {
      height: 714px;
    }
  }
}
@media (max-width: 420px) {
  .QRModal {
    .modal-content {
      padding: 10px !important;
    }
    .Heading {
      h3 {
        font-size: 16px;
      }
      svg {
        width: 10px;
        margin-top: 10px;
      }
    }
    img {
      width: 100%;
      height: 200px;
    }
    iframe,
    embed,
    video {
      height: 400px;
    }
  }
  .viewProductQR {
    .DesignDiv {
      height: 100%;
      .DesignerHeader {
        font-size: 16px;
      }
      .ArryImag {
        width: unset;
        height: 100%;
        display: block;
        .ImageDIVDIV {
          img,
          iframe,
          embed,
          video,
          .preview {
            width: 100% !important;
          }
        }
      }
    }
    .RowTextPro {
      height: 51px;
      width: 107%;
      margin-left: -1px;
      border-bottom: unset !important;
      border: 1px solid #d9d9d9 !important;
      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 12px;
      }
    }
    .UserHeader {
      font-size: 15px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .ProdRow {
      height: 321px;
    }
    .imageDivProd {
      padding: 20px 0px 20px 20px !important;
      svg {
        right: 10px !important;
        top: 15px !important;
        width: 15%;
      }
      img {
        height: 200px;
        border: 1px solid;
      }
      .ProdText {
        font-size: 16px;
      }
      .verified {
        left: 30px;
        bottom: 37%;
        width: 180px;
        span {
          font-size: 10px;
          margin-top: 8px;
        }
      }
    }
    .UserDetailsDiv {
      height: 686px;
    }
  }
}
@media (max-width: 510px) {
  .dbtn1 .Next,
  .dbtn1 .Previous {
    width: 33%;
    font-size: 12px;
  }
  .ViewImageQrCode {
    display: block;
    .imageDiv {
      width: unset !important;
      margin-bottom: 20px;
      background: unset !important;
      border: unset !important;
    }
    div {
      display: flex;
      justify-content: center;
    }
  }
}
.preview {
  height: 190px;
  border: 1px solid #d9d9d9 !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #c59c6c;
  border-radius: 15px;
  p {
    flex-wrap: wrap;
    width: 100%;
    white-space: wrap;
    text-align: center;
    padding: 5px 8px;
  }
}
.Pdf,
.Docx {
  font-size: 40px;
}
// .ril__image,
// .ril__imagePrev,
// .ril__imageNext {
//   width: 800px;
//   height: 800px;
//   object-fit: contain;
// }
.mobileLeftCard1 {
  display: none;
}
//media
@media (max-width: 767px) {
  .mobileLeftCard1 {
    display: block;
  }
  .mobileLeftCard {
    display: none !important;
  }
}
@media (min-width: 767px) and (max-width: 1124px) {
  .BannerDiv {
    .BannerImage {
      height: 600px;
    }
    .lines {
      left: 31px;
    }
    .lines1 {
      right: 41px;
    }
    .BannerContent {
      .cerDiv,
      .cerDiv1 {
        width: 430px;
        .certificationAuthenticity {
          font-size: 20px;
        }
        .certificationAuthenticityDes {
          font-size: 18px;
        }
      }
      .Banner1 {
        .leftImage {
          width: 120px;
          height: 120px;
        }
        .rightImage {
          width: 140px;
          height: 140px;
        }
        img {
          padding: 10px;
          margin-right: unset;
          object-fit: fill;
        }
        .line {
          // margin: 39px 0px 20px 0px;
          min-height: 70px;
        }
        .orgName {
          width: 100px;
          height: 100px;
          font-size: 12px;
          font-size: 400;
          padding: 2px;
        }
      }
      .Banner2 {
        margin-left: 10%;
        width: 80%;
        top: 50px;
        .Floral {
          font-size: 24px;
          margin-top: unset;
        }
        .AllProducts {
          font-size: 18px;
        }
        .Verified {
          font-size: 18px;
          padding: 24px;
          height: 80px;
          svg {
            width: 31px;
          }
          .d1 {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 641px) and (max-width: 768px) {
  .BannerDiv {
    .BannerImage {
      height: 580px;
    }
    .lines {
      left: 31px;
    }
    .lines1 {
      right: 41px;
    }
    .BannerContent {
      .cerDiv,
      .cerDiv1 {
        width: 436px;
        .certificationAuthenticity {
          font-size: 20px;
        }
        .certificationAuthenticityDes {
          font-size: 18px;
        }
      }
      .Banner1 {
        .rightImage {
          width: 130px;
          height: 130px;
        }
        .leftImage {
          width: 110px;
          height: 110px;
        }
        img {
          padding: 10px;
          margin-right: unset;
        }
        .line {
          // margin: 20px 0px 20px 0px;
          min-height: 50px;
        }
        .orgName {
          width: 100px;
          height: 100px;
          font-size: 12px;
          font-size: 400;
          padding: 2px;
        }
      }
      .Banner2 {
        margin-left: 10%;
        width: 80%;
        top: 50px;
        .Floral {
          font-size: 24px;
          margin-top: unset;
        }
        .AllProducts {
          font-size: 18px;
        }
        .Verified {
          font-size: 18px;
          padding: 24px;
          height: 80px;
          svg {
            width: 31px;
          }
          .d1 {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 481px) and (max-width: 640px) {
  .ProductJourneyPart {
    // height: 1550px;
    .rightCard {
      .rightToolTipCard {
        position: relative;
        .TriangleIcon {
          position: absolute;
          left: -16px;
          top: 15px;
        }
      }
    }
    .leftCard {
      .rightToolTipCard {
        position: relative;
        .TriangleIcon {
          position: absolute;
          right: 3px;
          top: 15px;
        }
      }
    }
    .rightToolTipSubCard {
      padding-top: 10px;
      font-size: 14px;
      display: block !important;
      height: 50px !important;
      div {
        padding-left: 30px;
      }
      .line {
        display: none !important;
      }
      .hrLine {
        display: block !important;
        width: 40px;
        margin-left: 29px;
        margin-bottom: 8px;
        margin-top: 8px;
      }
    }
    .ProductJourneyText {
      font-size: 20px !important;
      margin-top: 20px !important;
      margin-bottom: 20px !important ;
    }
    .Row {
      position: relative;
      .verticleLine {
        border-right: 5px solid #c59c6c;
        height: 358px;
      }
      .verticleLine1 {
        border-right: 5px solid #c59c6c;
        height: 120px;
      }
      .Icon1 {
        position: relative;
        left: 31px !important;
        top: 28px;
        svg {
          width: 31px;
        }
      }
      .rightCard,
      .leftCard {
        padding: 18px !important;
        svg {
          width: 21px;
        }
        .subDiv {
          font-family: Roboto;
          font-style: normal;
          margin-top: 17px;
        }
        .SmallHeading {
          font-size: 12px !important;
        }
        .SmallText {
          margin-left: 10px;
          text-transform: capitalize;
        }
        .subDiv1 {
          margin-top: 20px;
          height: 90px !important;

          .imagePos {
            margin-top: 19px !important;
            margin-left: 10px !important;
            img {
              width: 50px;
              height: 50px;
            }
            .Number {
              width: 20px !important;
              height: 20px !important;
            }
          }
          .subDiv12 {
            padding: 22px 14px 20px 14px !important;
            .ViewIcon {
              margin-top: 5px;
              cursor: pointer;
            }
          }
          .SmallHeading {
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            /* identical to box height */
            margin-left: 10px;
            color: #484848;
          }
          .SmallText {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .BannerDiv {
    .BannerImage {
      height: 500px;
    }
    .lines {
      left: 31px;
    }
    .lines1 {
      right: 41px;
    }
    .BannerContent {
      .cerDiv,
      .cerDiv1 {
        width: 300px;
        .certificationAuthenticity {
          font-size: 16px;
        }
        .certificationAuthenticityDes {
          font-size: 12px;
        }
      }
      .Banner1 {
        .rightImage {
          width: 130px;
          height: 130px;
        }
        .leftImage {
          width: 110px;
          height: 110px;
        }
        img {
          padding: 10px;
          margin-right: unset;
        }
        .line {
          margin: 20px 0px 20px 0px;
        }
        .orgName {
          width: 100px;
          height: 100px;
          font-size: 12px;
          font-size: 400;
          padding: 2px;
        }
      }
      .Banner2 {
        margin-left: 10%;
        width: 80%;
        top: 50px;
        .Floral {
          font-size: 18px;
          margin-top: unset;
        }
        .AllProducts {
          font-size: 16px;
        }
        .Verified {
          font-size: 12px;
          padding: 16px;
          height: 56px;
          svg {
            width: 21px;
          }
          .d1 {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .CardViewProductHeader {
    .ImageDis,
    .NoPreview {
      height: 300px;
    }
    .RowTextPro,
    .RowTextPro1 {
      height: 61px;
      .ColTextPro,
      .ColTextPro1 {
        margin-top: unset !important;
      }
      .Text {
        text-align: unset !important;
        margin-left: unset;
        font-size: 12px;
      }
    }
  }
  .BannerDiv {
    .BannerImage {
      height: 500px;
    }
    .lines {
      left: 31px;
    }
    .lines1 {
      right: 41px;
    }
    .BannerContent {
      position: absolute;
      width: 100%;
      top: 50px;
      .cerDiv,
      .cerDiv1 {
        width: 270px;
        .certificationAuthenticity {
          font-size: 16px;
        }
        .certificationAuthenticityDes {
          font-size: 12px;
          padding: unset;
          padding-top: 5px;
        }
      }
      .Banner1 {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
        .rightImage {
          width: 130px;
          height: 130px;
        }
        .leftImage {
          width: 110px;
          height: 110px;
          margin-right: 4px;
        }
        img {
          padding: 10px;
          margin-right: unset;
        }
        .line {
          min-height: 40px;
          // margin: 20px 0px 20px 0px;
        }
        .orgName {
          width: 100px;
          height: 100px;
          font-size: 10px;
          font-size: 400;
          padding: 2px;
          margin-left: 25px;
        }
      }
      .Banner2 {
        margin-left: 15%;
        width: 70%;
        top: 50px;
        .Floral {
          font-size: 14px;
          margin-top: unset;
        }
        .AllProducts {
          font-size: 12px;
        }
        .Verified {
          font-size: 12px;
          padding: 12px;
          height: 56px;
          svg {
            width: 21px;
          }
          .d1 {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 899px) and (max-width: 1024px) {
  .ProductJourneyPart {
    // height: 1830px !important;
    .leftCard {
      .leftArrow {
        position: absolute;
        right: -1px !important;
      }
    }
    .leftCard .rightToolTipCard .TriangleIcon {
      right: -17px !important;
    }
    .rightToolTipSubCard {
      font-size: 14px !important;
      display: block !important;
      height: 50px !important;
      padding-top: 12px;
      div {
        padding-left: 15px !important;
      }
      .line {
        display: none !important;
      }
      .hrLine {
        display: block !important;
        width: 40px;
        margin-left: 17px;
        margin-bottom: 8px;
        margin-top: 8px;
      }
    }
    .ProductJourneyText {
      font-size: 20px !important;
      margin-top: 15px !important;
      margin-bottom: 15px !important;
    }
    .Row {
      .verticleLine {
        height: 430px !important;
      }
      .verticleLine1 {
        height: 120px;
      }
      .Icon1 {
        left: 31px !important;
        svg {
          width: 31px;
        }
      }
      .rightCard,
      .leftCard {
        padding: 18px !important;

        svg {
          width: 21px;
        }
        .subDiv {
          margin-top: 5px !important;
        }
        .SmallHeading {
          font-size: 14px !important;
        }
        .SmallText {
          margin-left: 10px;
        }
        .subDiv1 {
          margin-top: 8px !important;
          height: 122px !important;
          display: flex !important;

          .imagePos {
            margin-top: 9px !important;
            margin-left: 10px !important;
            img {
              width: 100px;
              height: 100px;
              padding: 1px;
            }
            .Number {
              width: 20px !important;
              height: 20px !important;
              right: 10px !important;
              top: 10px !important;
            }
          }
          .subDiv12 {
            padding: 20px 13px 20px 3px !important;
            align-items: center;
            // .dGridM{
            //   display: grid;
            // }
            .ViewIcon {
              margin-top: 5px;
              cursor: pointer;
            }
          }
          .SmallHeading {
            font-size: 12px;
          }
          .SmallText {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 898px) {
  .ProductJourneyPart {
    // height: 1830px !important;
    .leftCard {
      .leftArrow {
        position: absolute;
        right: 2px !important;
      }
    }
    .leftCard .rightToolTipCard .TriangleIcon {
      right: -17px !important;
    }
    .rightToolTipSubCard {
      font-size: 14px !important;
      display: block !important;
      height: 50px !important;
      padding-top: 12px;
      div {
        padding-left: 15px !important;
      }
      .line {
        display: none !important;
      }
      .hrLine {
        display: block !important;
        width: 40px;
        margin-left: 17px;
        margin-bottom: 8px;
        margin-top: 8px;
      }
    }
    .ProductJourneyText {
      font-size: 20px !important;
      margin-top: 15px !important;
      margin-bottom: 15px !important;
    }
    .Row {
      .verticleLine {
        height: 430px !important;
      }
      .verticleLine1 {
        height: 120px;
      }
      .Icon1 {
        left: 31px !important;
        svg {
          width: 31px;
        }
      }
      .rightCard,
      .leftCard {
        padding: 18px !important;
        svg {
          width: 21px;
        }
        .subDiv {
          margin-top: 5px !important;
        }
        .SmallHeading {
          font-size: 14px !important;
        }
        .SmallText {
          margin-left: 10px;
        }
        .subDiv1 {
          margin-top: 8px !important;
          height: 239px !important;
          display: block !important;

          .imagePos {
            margin-top: 9px !important;
            margin-left: 10px !important;
            img,
            iframe,
            video {
              width: 95%;
              height: 150px;
              padding: 1px;
            }
            .Number {
              width: 20px !important;
              height: 20px !important;
              right: 21px !important;
              top: 10px !important;
            }
          }
          .subDiv12 {
            padding: 20px 13px 20px 3px !important;
            align-items: center;
            // .dGridM{
            //   display: grid;
            // }
            .ViewIcon {
              margin-top: 5px;
              cursor: pointer;
            }
          }
          .SmallHeading {
            font-size: 12px;
          }
          .SmallText {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 380px) and (max-width: 480px) {
  .ProductJourneyPart {
    // height: 2090px !important;

    .rightToolTipSubCard {
      font-size: 12px !important;
      display: block !important;
      height: 50px !important;
      padding-top: 12px;
      div {
        padding-left: 30px;
      }
      .line {
        display: none !important;
      }
      .hrLine {
        display: block !important;
        width: 40px;
        margin-left: 29px;
        margin-bottom: 8px;
        margin-top: 8px;
      }
    }
    .ProductJourneyText {
      font-size: 20px !important;
      margin-top: 18px !important;
      margin-bottom: 18px !important;
    }
    .Row {
      .verticleLine {
        height: 498px !important;
      }
      .verticleLine1 {
        height: 120px;
      }
      .Icon1 {
        left: 31px !important;
        svg {
          width: 31px;
        }
      }
      .rightCard,
      .leftCard {
        padding: 18px !important;
        svg {
          width: 21px;
        }
        .subDiv {
          margin-top: 17px;
        }
        .SmallHeading {
          font-size: 12px !important;
        }
        .SmallText {
          margin-left: 10px;
        }
        .subDiv1 {
          margin-top: 20px;
          height: 242px !important;
          display: block !important;

          .imagePos {
            margin-top: 15px !important;
            margin-left: 10px !important;
            img,
            iframe,
            video {
              width: 96%;
              height: 150px;
              padding: 1px;
            }
            .Number {
              width: 20px !important;
              height: 20px !important;
              right: 21px !important;
              top: 10px !important;
            }
          }
          .subDiv12 {
            padding: 20px 10px 20px 10px !important;
            .ViewIcon {
              margin-top: 5px;
              cursor: pointer;
            }
          }
          .SmallHeading {
            font-size: 12px;
          }
          .SmallText {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 379px) {
  .ProductJourneyPart {
    // height: 2090px !important;

    .rightToolTipSubCard {
      font-size: 11px !important;
      display: block !important;
      height: 50px !important;
      padding-top: 12px;
      div {
        padding-left: 15px !important;
      }
      .line {
        display: none !important;
      }
      .hrLine {
        display: block !important;
        width: 40px;
        margin-left: 17px;
        margin-bottom: 8px;
        margin-top: 8px;
      }
    }
    .ProductJourneyText {
      font-size: 14px !important;
      margin-top: 15px !important;
      margin-bottom: 15px !important;
    }
    .Row {
      .verticleLine {
        height: 498px !important;
      }
      .verticleLine1 {
        height: 120px;
      }
      .Icon1 {
        left: 31px !important;
        svg {
          width: 31px;
        }
      }
      .rightCard,
      .leftCard {
        padding: 18px !important;
        svg {
          width: 21px;
        }
        .subDiv {
          margin-top: 5px !important;
        }
        .SmallHeading {
          font-size: 11px !important;
        }
        .SmallText {
          margin-left: 10px;
        }
        .subDiv1 {
          margin-top: 8px !important;
          height: 242px !important;
          display: block !important;

          .imagePos {
            margin-top: 9px !important;
            margin-left: 10px !important;
            img,
            iframe,
            video {
              width: 95%;
              height: 120px;
              padding: 1px;
            }
            .Number {
              width: 20px !important;
              height: 20px !important;
              right: 21px !important;
              top: 10px !important;
            }
          }
          .subDiv12 {
            padding: 20px 10px 20px 10px !important;
            align-items: center;
            .dGridM {
              display: grid;
            }
            .ViewIcon {
              margin-top: 5px;
              cursor: pointer;
            }
          }
          .SmallHeading {
            font-size: 12px;
          }
          .SmallText {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 640px) {
  .CardViewProductHeader {
    .tableProductHeader {
      font-size: 16px;
      height: 60px;
    }
  }
  .TooltipP:hover:before,
  .TooltipW:hover:before,
  .TooltipF:hover:before {
    bottom: 90px;
    font-size: 10px;
    white-space: break-spaces !important;
    flex-wrap: wrap;
  }
  .TooltipP:hover:after,
  .TooltipW:hover:after,
  .TooltipF:hover:after {
    bottom: 100px;
  }
  .resetBtnMobile {
    background-color: #1a1a1a;
    height: 40px;
    margin-top: 20px;
  }
  .CardViewProductHeader {
    // .btn-secondary {
    //   color: #fff;
    //   background-color: #1a1a1a !important;
    //   border-color: #1a1a1a;
    // }
  }
}
@media (min-width: 641px) and (max-width: 768px) {
  .d768640 {
    display: none !important;
  }
}
@media (min-width: 641px) {
  .resetBtnMobile {
    display: none;
  }
}

.CreateProductHeader {
  border: 1px solid #d9d9d9;
  margin: 20px;
  // height: 70px;
  .InputColP {
    .form-check {
      font-size: 25px;
      // margin-top: -8px;
      margin-left: 20px;
    }
    .form-check-label {
      font-size: 16px;
      font-weight: 700;
      font-family: Roboto;
      margin-top: 6px;
    }
    .form-check-input:checked {
      background-color: #1a1a1a;
      border-color: #1a1a1a;
    }
    .form-check-input:focus {
      border-color: #454343;
      outline: 0;
      box-shadow: 0 0 0 0.25rem, rgba(9, 9, 9, 50%) !important;
    }
  }
}
.CardViewProductHeader {
  position: relative;
  .slick-prev {
    position: absolute;
    left: 50px;
    z-index: 1000;
  }

  .slick-next {
    position: absolute;
    right: 50px;
    z-index: 1000;
  }
  .slick-next::before,
  .slick-prev::before {
    font-size: 60px;
    // background-color: #000;
    z-index: 999;
  }
  .slick-next:before {
    content: url(./assets/images/Group32.png) !important;
  }
  .slick-prev:before {
    content: url(./assets/images/Group33.png) !important;
  }
}
.rs-toggle-lg .rs-toggle-presentation:after {
  border-radius: 11px;
  height: 22px;
  top: 4px;
  width: 22px;
  color: #fff;
}
.rs-toggle-lg .rs-toggle-inner {
  font-size: 14px;
  color: #fff !important;
}

.rs-toggle-checked .rs-toggle-presentation {
  background-color: #262d22;
}
.rs-toggle-checked .rs-toggle-presentation:hover {
  background-color: #262d22;
}
.rs-toggle-presentation:hover {
  background-color: rgb(149, 12, 12);
}
.rs-toggle-presentation {
  background-color: rgb(149, 12, 12);
}
// .rs-toggle-lg.rs-toggle-checked .rs-toggle-presentation .rs-toggle-inner {
//   margin-left: 17px;
//   margin-right: 30px;
//   font-size: 18px;
// }
.CarouselForProduct {
  position: relative;
  height: 434px;
  .viewIconForPreview {
    z-index: 99;
    position: absolute;
    bottom: 14px;
    left: 5px;
    cursor: pointer;
  }
}
