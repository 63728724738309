// login page
.LoginpageNew {
  .form-check-input:checked {
    background-color: rgb(6, 95, 55);
    border-color: rgb(6, 95, 55);
  }
  .logoBanner {
    min-height: 100vh;
  }
  .LogoImage {
    margin-top: 100px;
  }
  .LoginpageGrid {
    background-color: #f8fafc;
    position: relative;
    .parentForm {
      width: 480px;
      margin: auto;
      // padding: 20px 78px;
      position: relative;
      top: 200px;
      .icon {
        position: absolute;
        right: 12px;
        top: 12px;
        cursor: pointer;
      }

      .MainHeading {
        position: absolute;
        top: -160px;
        font-family: Inter;

        // border-radius: 12px;
        .sp1,
        .sp2 {
          font-size: 28px;
          font-style: italic;
        }
        .sp1 {
          color: #7e462a;
        }
        .sp2 {
          color: #373218;
          font-weight: 600;
        }
        p {
          font-size: 12px;
          color: #000;
          font-family: inter;
          font-style: oblique;
        }
      }
      h4 {
        color: #1c1c1c;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 20px;
      }
      .enterEmail {
        color: rgba(28, 28, 28, 0.8);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 30px;
      }
      .forgetPswd {
        color: #7e462a;
      }
    }
  }
  .textImage {
    position: absolute;
    left: 70px;
    top: 350px;
    width: auto;
  }
  .labelName {
    color: #1c1c1c;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 8px;
    span {
      color: red;
    }
  }
  .inputField {
    background: #fff !important;
    border: 0.5px solid #484848;
    height: 48px;
    min-width: 100%;
    border-radius: 4px;
    // color: #fff;
    padding-left: 15px;
    width: 100%;
    color: #585757;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .loginBtn {
    width: 100%;
    margin: 10px 0px;
    height: 50px;
    border-radius: 8px;
    background: #7e462a;
    font-weight: 500;
    font-size: 20px;
    color: #fff;
    text-align: center;
    font-family: Inter;
    // font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
  .forgetPswd {
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    font-weight: 400;
    font-size: 15px;
  }
}
@media (min-width: 900px) and (max-width: 1200px) {
  .LoginpageNew {
    .LoginpageGrid {
      .parentForm {
        width: 380px;
      }
    }
  }
}
@media (max-width: 899px) {
  .logoBanner {
    display: none;
  }
}
@media (max-width: 640px) {
  .LoginpageNew {
    .LoginpageGrid {
      .parentForm {
        .MainHeading {
          // background: #edd984;
          padding: 20px 20px 25px 20px;
          .sp1,
          .sp2,
          p {
            color: #fff;
          }
        }
        label[class="form-check-label"] {
          color: #fff;
        }
      }
    }
  }
}
@media (min-width: 321px) and (max-width: 640px) {
  .LoginpageNew {
    overflow: hidden !important;
    background-image: url("../../assets/images/login.png");
    min-height: 100vh;
    .LoginpageGrid {
      background-color: unset;
      .parentForm {
        width: 100%;
        padding: 20px 40px;
        h4,
        .enterEmail,
        .labelName {
          color: #fff;
        }
      }
    }
  }
}
@media (max-width: 320px) {
  .LoginpageNew {
    background-image: url("../../assets/images/login.png");
    min-height: 100vh;
    .LoginpageGrid {
      background-color: unset;
      .parentForm {
        width: 100%;
        padding: 10px 20px;
        top: 100px;
        .MainHeading {
          top: -80px;
          font-size: 20px;
        }

        h4,
        .enterEmail,
        .labelName {
          color: #fff;
        }
        h4 {
          font-size: 22px;
        }
        .enterEmail {
          font-size: 16px;
        }
        .labelName {
          font-size: 16px;
        }
        .loginBtn {
          font-size: 16px;
        }
      }
    }
  }
}
.responseMsg {
  color: red;
  margin-bottom: 10px;
}
.responseMsgSucces {
  color: rgb(13, 109, 43);
  margin-bottom: 10px;
}
