/* header */
// body {
//   overflow: hidden;
// }
@mixin fontd($clr, $size, $weight) {
  font-family: "Inter", Sans-serif;
  font-size: $size;
  font-weight: $weight;
  color: $clr;
}

@mixin font16($clr) {
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: $clr;
}

@mixin font13() {
  color: #FFFFFF99;
  font-family: "Inter", Sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
}

@mixin headercontent {
  color: #000;
  font-size: 34px;
  font-weight: 600;
  line-height: 133%;
  letter-spacing: -0.8px;
  text-align: center;
}

.page-content {
  // overflow-y: scroll;
  height: 100vh;
  padding-top: 150px;
}


.navbar-header {
  position: fixed;
  width: 100%;
  z-index: 999;

  // margin-top: -150px;
  .dropdown {
    .dropdown-menu {
      position: fixed !important;
      right: 2% !important;
      top: 18px !important;
      z-index: 9999;
    }
  }
}

@mixin mobileHeaderCss {
  .Header-routes-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    width: 100%;
    height: auto;
    border: 1px solid rgb(181, 167, 167);
    background-color: #FFF;

    :hover {
      background-color: #3f444b;
      @include font16(#ffff)
    }

    div {
      font-size: 16px;
      color: #080808CC;
      cursor: pointer;
      width: 100%;
      padding: 10px;

      a {
        text-decoration: none;
        @include font16(#2b2727)
      }

    }
  }
}


@mixin nav {
  .Header-routes {
    display: flex;
    align-items: center;
    text-align: center;

    li {
      float: left;
      list-style-type: none;
      align-self: center;
      fill: #080808CC;
      font-size: 16px;
      color: #080808CC;

      a {
        text-decoration: none;
        margin-right: 15px;
        @include font16(#000)
      }

    }

    li:nth-child(5) {
      border: 1px solid;
      padding: 8px;
      border-radius: 5px;
      box-sizing: content-box;
    }

  }
}



.Header-Card {
  height: 80px;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  .desktop-header {
    display: flex;
  }

  .mobile-header {
    display: none;
  }


  .Header-Container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Hastakala-logo {
      padding: 5px 0px;

      .sp1,
      .sp2 {
        font-size: 28px;
        font-style: italic;
        color: #7e462a;
      }

      .sp2 {
        color: #373218;
        font-weight: 600;
      }

      p {
        font-size: 12px;
        color: #000;
        font-family: inter;
        font-style: oblique;
        margin: 0px;
      }
    }

    @include nav();

  }
}

// Supporting makers
.suporting-makers {
  height: auto;
  padding: 40px 0px;
//slick
 .slick-prev {
    position: absolute;
    left: 30px;
    z-index: 1;
  }
    .slick-next:before {
    content: url(./assets/icons/next.png) !important;
    z-index: 1;
  }
  .slick-prev:before {
    content: url(./assets/icons/previous.png) !important;
    z-index: 1;
  }
  .slick-next {
    position: absolute;
    right: 75px;
    z-index: 1;
  }
  // .slick-prev,.slick-next{
  //   background: #133304;
  //   // opacity: 0.7;
  //   width: 55px;
  //   border-radius: 50%;
  //   height: 55px;
  // }
  img {
    height: 500px;
    width: 100%;
    border-radius: 30px;
  }

  .supporting-header {
    padding-top: 60px;

    h2 {
      color: #362706;
      font-family: "Inter", Sans-serif;
      font-size: 48px;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: -2.5px;
    }

    p {
      color: #535B62;
      font-family: "Inter", Sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
      width: 80%;
      margin-top: 10px;
    }

    button {
      font-family: "Inter", Sans-serif;
      font-size: 16px;
      font-weight: 600;
      fill: #FFFFFF;
      color: #FFFFFF;
      background-color: #362706;
      border-style: solid;
      border-width: 1px 1px 1px 1px;
      border-color: #2F363F4D;
      border-radius: 8px 8px 8px 8px;
      padding: 17px 36px 17px 36px;
      text-transform: capitalize;
      margin-top: 70px;
    }

  }
}

// hastakala paramank div
.hastakala-paramank-div {
  height: auto;
  background-color: #f5eee0;
  padding: 50px 0px;

  // common card
  .hastakala-card {
    height: 200px;
    width: 100%;
    border-radius: 15px;
    text-align: center;
    margin: auto;
    padding: 48px 30px;

    .content {
      color: #2F363F;
      font-family: "Inter", Sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 140%;
    }
  }
}

// why hastakala pramank
.why-hastakala-parmank,
.how-to-use {
  height: auto;
}

.how-to-use {
  margin-top: 100px;
  margin-bottom: 100px;

  .header-title {
    @include headercontent()
  }

  .see-demo {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    button {
      font-family: "Inter", Sans-serif;
      font-size: 16px;
      font-weight: 600;
      fill: #FFFFFF;
      color: #FFFFFF;
      background-color: #362706;
      border-style: solid;
      border-width: 1px 1px 1px 1px;
      border-color: #2F363F4D;
      border-radius: 8px 8px 8px 8px;
      padding: 17px 36px 17px 36px;
      margin: auto;
      text-align: center;
    }
  }
}
.why-hastakala-parmank {
  padding: 100px 0px;
 

  .desktop-display-whyhastakala {
    display: contents;
  }

  .mobile-display-whyhastakala {
    display: none;
  }

  .why-hastakala-right,
  .why-hastakala-left {
    padding-top: 30px;
 
    .Content {
      padding: 20px;
    }

    h2 {
      color: #2F363F;
      font-family: "Inter", Sans-serif;
      font-size: 28px;
      font-weight: 600;
      line-height: 38px;
      letter-spacing: -0.8px;
    }

    p {
      color: #535B62;
      font-family: "Inter", Sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
    }
  }
}



// impact stack holders
.impact-stack-holders {
  height: auto;
  background-color: #f5eee0;
  padding: 80px 0px 70px 0px;

  .parent-grid-card-div {
    // position: relative;

    .policy-card,
    .artisian,
    .end-user {
      background-color: #FFFF !important;
      border-radius: 15px;
      padding: 40px;
      box-sizing: border-box;

      h3 {
        color: #2F363F;
        font-family: "Inter", Sans-serif;
        font-size: 24px;
        font-weight: 600;
      }
    }

    .artisian,
    .end-user {
      height: 225px;

      .Polymaker-Number {
        color: #000;
        font-family: "Inter", Sans-serif;
        font-size: 32px;
        font-weight: 600;
        line-height: 44.8px;
        margin-top: 20px;
      }

      .Polymaker-content {
        color: #535B62;
        font-family: "Inter", Sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        // width: 80%;
      }
    }

    .policy-card {
      height: 466px;
      position: relative;

      .Polymaker-Number {
        color: #D62B33;
        font-family: "Inter", Sans-serif;
        font-size: 32px;
        font-weight: 600;
        line-height: 44.8px;
        margin-top: 20px;
      }

      .Polymaker-content {
        color: #535B62;
        font-family: "Inter", Sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        width: 80%;
      }

      .Trillion {
        position: absolute;
        color: #7C7E80;
        font-family: "Inter", Sans-serif;
        font-size: 12px;
        font-weight: 400;
        font-style: italic;
        line-height: 19.6px;
        bottom: 40px;
      }
    }
  }
}

@mixin headertitlewhite {
  .header-title {
    color: #FFF;
    font-size: 34px;
    font-weight: 600;
    line-height: 133%;
    letter-spacing: -0.8px;
    text-align: center;
  }
}

.productinfo-div {
  padding-top: 50px;
  @include headertitlewhite();

  .product-grid {
    margin-top: 30px;
    margin-bottom: 100px;

    .product-card {
      height: 300px;
      padding: 30px;
      border-radius: 25px;

      .Card-Header {
        @include fontd("#FFF", 30px, 700);
        background-color: #362706;
        color: #FFFF;
        border-radius: 10px
      }

      .Card-content {
        .value {
          @include fontd("#D62B33", 35px, 400);
          color: #D62B33
        }

        .p-desc {
          @include fontd("#333", 16px, 400);
          text-align: justify;

        }
      }
    }
  }

}

// benifits of stackholders and footer
.benifits-stackholders,
.footer,
.productinfo-div {
  height: auto;
  background-color: #362706;
  padding: 40px 0px;
}

.benifits-stackholders {
  padding-top: 90px !important;

  .desktop-display-stackholder {
    display: contents;
  }

  .mobile-display-stackholder {
    display: none;
  }

  @include headertitlewhite();


  .benifits-left-div,
  .benifits-right-div {
    height: 300px;
  }

  .mobile-benifits-div {
    padding-bottom: 20px;

    img {
      min-width: 100% !important;
      padding: 0px 20px 0px 20px;
      border-radius: 10%;
    }

    .bottom-part {
      padding: 20px 0px 0px 20px;
    }

    h2 {
      color: #FFFFFF;
      font-family: "Inter", Sans-serif;
      font-size: 28px;
      font-weight: 600;
      line-height: 38px;
      letter-spacing: -0.8px;
      padding: 20px 0px 10px 20px;
    }
  }

  .benifits-left-div,
  .benifits-right-div,
  .mobile-benifits-div {
    box-sizing: border-box;
    border: 2px solid #FFF;
    border-radius: 20px;
    margin-top: 70px;

    .left-part,
    .right-part {
      padding: 60px 5px 0px 30px;
    }

    img {
      max-width: 100%;
    }

    h2 {
      color: #FFFFFF;
      font-family: "Inter", Sans-serif;
      font-size: 28px;
      font-weight: 600;
      line-height: 38px;
      letter-spacing: -0.8px;
    }



    .description-points {
      color: #FFF;
      font-family: "Inter", Sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      margin-top: 8px;

      li {
        margin-top: 8px;
      }


    }
  }

}

.footer {
  margin-bottom: 1px;
  padding-top: 100px;

  .footer-container {
    display: flex;
    justify-content: space-evenly;
    color: #f3e6e6;

    ul {
      li {
        list-style-type: none;
        color: #FFFFFF99 !important;
        transition: color 0.3s;
        padding-top: 8px;
        @include font13();

      }


      li:nth-child(1) {
        font-weight: 700;
        font-size: 14px;
      }
    }

    a {
      color: #FFFFFF99;
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
    }

    .contact-us {
      li {
        display: flex;
        justify-content: flex-start;

        img {
          margin-right: 16px;
        }
      }
    }

    .footer-hastakala-logo {
      font-size: 20px;

      p {
        font-size: 8px;
        margin: 0px;
        padding-left: 7px;
      }
    }

    .content {
      @include font13();
    }

    border-bottom: 0.1px solid #dcc9c9;
  }

  .allrights {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
    @include font13()
  }
}

.impact-stack-holders {
  .header {
    .header-title {
      margin-bottom: 50px;
    }
  }
}

.impact-stack-holders,
.hastakala-paramank-div,
.why-hastakala-parmank {

  // header
  .header {
    font-family: "Inter", Sans-serif;
    text-align: center;
    // padding: 20px 80px;

    .header-title {
      color: #2F363F;
      font-size: 34px;
      font-weight: 600;
      line-height: 133%;
      letter-spacing: -0.8px;

      span {
        margin-right: 2px;
        font-style: italic;
        font-weight: 400;
      }
    }

    p {
      color: #535B62;
      font-size: 18px;
      font-weight: 400;
      line-height: 140%;
    }
  }
}

// media 
@media screen and (min-width:1124px) {
  .hastakala-paramank-div {
    .header {
      p {
        padding: 0px 120px
      }
    }
  }
}

@media screen and (min-width:899px) and (max-width:1024px) {
  .suporting-makers {
    mar .supporting-header {
      h2 {
        font-size: 38px;

      }

      p {
        font-size: 14px;
        width: 90%;
      }
    }
  }
}

@media screen and (max-width:899px) {
  .suporting-makers {
    .supporting-header {
      padding-top: 0px;
    }
  }

}

@media screen and (max-width:768px) {
  .hastakala-paramank-div {
    .header {
      padding: unset;

      p {
        padding: unset
      }
    }
  }
}



@media screen and (max-width:600px) {

  // products
  .productinfo-div {
    .product-grid {
      margin-bottom: 50px;

      .product-card {
        height: auto;

        h4 {
          @include fontd("#333", 25px, 700)
        }

        .value {
          @include fontd("#333", 30px, 400)
        }

        .p-desc {
          @include fontd("#333", 14px, 400)
        }
      }
    }

  }

  .benifits-stackholders,
  .why-hastakala-parmank {

    .desktop-display-stackholder,
    .desktop-display-whyhastakala {
      display: none !important;
    }

    .mobile-display-stackholder,
    .mobile-display-whyhastakala {
      display: contents !important;
    }

    .benifits-left-div,
    .benifits-right-div {
      height: auto;
    }
  }

  .why-hastakala-parmank {
    .mobile-display-whyhastakala {
      .Content {
        padding: unset;
      }
    }
  }
}

@media screen and (min-width:601px) and (max-width:670px) {
  .benifits-stackholders {

    .benifits-left-div,
    .benifits-right-div {

      .left-part,
      .right-part {
        padding: 30px 0px 0px 20px;
      }
    }
  }
}

@media screen and (max-width:678px) {
  @include mobileHeaderCss();

  .Header-Card {
    .desktop-header {
      display: none !important;
    }

    .mobile-header {
      display: flex;


      img {
        cursor: pointer;
      }


    }
  }
}

@media screen and (max-width:540px) {
  .suporting-makers {
    .supporting-header {
      h2 {
        font-size: 38px;
      }

      p {
        font-size: 14px;
        width: 95%;
      }
    }
  }

}

@media screen and (min-width:430px) and (max-width:540px) {
  .impact-stack-holders {
    .parent-grid-card-div {

      .policy-card,
      .artisian,
      .end-user {
        .Polymaker-Number {
          font-size: 24px;
        }

        .Polymaker-content {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width:429px) {
  .impact-stack-holders {
    .parent-grid-card-div {

      .policy-card,
      .artisian,
      .end-user {
        .Polymaker-Number {
          font-size: 18px;
          margin-top: unset;
        }

        .Polymaker-content {
          font-size: 10px;
        }
      }
    }
  }
}

// terms and conditions
.Terms-conditions,
.Privacy-policy {
  padding: 100px;
  counter-reset: section;

  h2 {
    color: #2F363F;
    font-family: "Inter", Sans-serif;
    font-size: 34px;
    font-weight: 600;
    line-height: 45.22px;
    letter-spacing: -0.8px;
    text-align: center;
  }

  b,
  .title {
    counter-reset: subsection;
    color: #2F363F;
    font-family: "Inter", Sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 45.22px;
    letter-spacing: -0.8px;
  }

  p,
  .sub-element {
    color: #535B62;
    font-family: "Inter", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 30.22px;

  }

  b::before {
    counter-increment: section;
    content: counter(section) ". ";
  }

  p::before {
    counter-increment: subsection;
    content: counter(section) "." counter(subsection) " ";
  }
}

@media screen and (max-width:640px) {

  .Terms-conditions,
  .Privacy-policy {
    padding: 10px;

    h2 {
      font-size: 24px;
    }

    p,
    b,
    .title,
    .sub-element {
      font-size: 12px;
    }
  }
}

// pricing
.pricing {
  padding: 70px;

  a:hover {
    text-decoration: none;
  }

  .smart-tag {
    color: #000;
    padding-top: 20px;

    h3 {
      color: #000;
    }

    p {
      font-size: 18px;
    }
  }

  // background-color: var(--token-6365057e-d6d3-476b-a98f-53e49277c5c3, #000213);
  .pricing-card {
    color: #000;
    height: 550px;

    .card-header {
      padding-top: 20px;
      padding-bottom: 20px;
      background-color: #f3eccc; // box-shadow: rgba(255, 255, 255, 0.46) 0px 0.48175px 0.86715px -1.33333px inset, rgba(255, 255, 255, 0.396) 0px 1.83083px 3.29549px -2.66667px inset, rgba(255, 255, 255, 0.12) 0px 8px 14.4px -4px inset, rgba(255, 255, 255, 0.46) 0px -0.48175px 0.86715px -1.33333px inset, rgba(255, 255, 255, 0.396) 0px -1.83083px 3.29549px -2.66667px inset, rgba(255, 255, 255, 0.12) 0px -8px 14.4px -4px inset;
      border-radius: 55px 55px 0px 0px;
    }

    .card-body {
      height: 400px;
      padding: 30px;
      background-color: #e7d792;
      border-radius: 0px 0px 55px 55px;

      .text,
      ul li {
        font-size: 18px;
      }

      hr {
        height: 2px;
      }

      .text {
        height: 110px;
      }
    }

  }

  h2 {
    text-align: center;
    color: #000;

  }
}

@media (max-width:1198px) {
  .pricing {
    .pricing-card {
      height: auto;

      .text {
        height: auto;
      }
    }
  }
}

@media screen and (max-width:580px) {
  .pricing {
    padding: 10px;
    // h2{
    //   font-size: 34px;
    // }
    // .text,ul li{
    //   font-size: 12px;
    // }
  }
}

a:hover {
  text-decoration: none;
}
.react-player-div{
  width: 100%;
    video{
      border-radius: 30px;
      border: 1px solid !important;
      background-color: #000;
      // min-height: fit-content;
      // background-image: url('./assets/images/sustainable_consumption.jpg') !important;
    }
  }
  @media (min-width:640px) and (max-width:768px) {
    .Header-Container {
    .Hastakala-logo {
      padding: 5px 0px;

      .sp1,
      .sp2 {
        font-size: 20px !important;
      }

      p {
        font-size: 8px !important;
      }
    }

    @include nav();

  }
  }